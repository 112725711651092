"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Theme = void 0;
var Theme;
exports.Theme = Theme;

(function (Theme) {
  Theme["DARK"] = "dark";
  Theme["LIGHT"] = "light";
})(Theme || (exports.Theme = Theme = {}));