"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useId = exports.useClickOutside = exports.navigateToNew = exports.navigateToLegacy = exports.generateNewURL = exports.generateLegacyURL = exports.generateBaseURL = exports.extractPowerType = exports.REACT_BASENAME = exports.LEGACY_BASENAME = exports.BASENAME = void 0;

var _react = require("react");

var _nanoid = require("nanoid");

var BASENAME = process && process.env && process.env.BASENAME || "/MAAS";
exports.BASENAME = BASENAME;
var LEGACY_BASENAME = process && process.env && process.env.LEGACY_BASENAME || "/l";
exports.LEGACY_BASENAME = LEGACY_BASENAME;
var REACT_BASENAME = process && process.env && process.env.REACT_BASENAME || "/r";
exports.REACT_BASENAME = REACT_BASENAME;

var generateLegacyURL = function generateLegacyURL(route) {
  return "".concat(BASENAME).concat(LEGACY_BASENAME).concat(route || "");
};

exports.generateLegacyURL = generateLegacyURL;

var generateNewURL = function generateNewURL(route) {
  var appendBase = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return "".concat(appendBase ? BASENAME : "").concat(REACT_BASENAME).concat(route || "");
};

exports.generateNewURL = generateNewURL;

var generateBaseURL = function generateBaseURL(route) {
  return "".concat(BASENAME).concat(route || "");
};

exports.generateBaseURL = generateBaseURL;

var pushRoute = function pushRoute(route) {
  return window.history.pushState(null, null, route);
};

var navigate = function navigate(route, evt) {
  if (evt) {
    // Handle ctrl/command/middle clicking etc. the links to open in a new tab.
    // Inspired by how this is handled in react-router:
    // https://github.com/ReactTraining/react-router/blob/f466c8c4156b6fcdb6baf4fcc723758f7eceeb4b/packages/react-router-dom/modules/Link.js#L43
    var isLeftClick = evt.button === 0;
    var hasModifierKey = evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey;

    if (isLeftClick && !hasModifierKey) {
      evt.preventDefault();
      pushRoute(route);
    }
  } else {
    // If there is no event (e.g. for a redirect) then navigate directly.
    pushRoute(route);
  }
};

var navigateToLegacy = function navigateToLegacy(route, evt) {
  navigate(generateLegacyURL(route), evt);
};

exports.navigateToLegacy = navigateToLegacy;

var navigateToNew = function navigateToNew(route, evt) {
  navigate(generateNewURL(route), evt);
};
/**
 * Get the formatted power type from a power type description.
 * @param description - A power type description.
 * @param powerType - A power type.
 * @return The formatted power type or the original power type key.
 */


exports.navigateToNew = navigateToNew;

var extractPowerType = function extractPowerType(description, powerType) {
  if (!powerType) {
    return null;
  }

  if (!description) {
    return powerType;
  }

  var position = description.toLowerCase().indexOf(powerType.toLowerCase());
  return position === -1 ? powerType : description.substring(position, position + powerType.length);
};
/**
 * Get a random ID string
 * @returns non-secure random ID string
 */


exports.extractPowerType = extractPowerType;

var useId = function useId() {
  return (0, _react.useRef)((0, _nanoid.nanoid)()).current;
};
/**
 * Handle clicks outside an element.
 * @returns An id and ref to pass to the element to handle clicks
 * outside of.
 */


exports.useId = useId;

var useClickOutside = function useClickOutside(onClickOutside) {
  var wrapperRef = (0, _react.useRef)(null);
  var id = useId();
  var handleClickOutside = (0, _react.useCallback)(function (evt) {
    var _evt$target, _wrapperRef$current;

    var target = evt.target; // The target might be something like an SVG node which doesn't provide
    // the class name as a string.

    var isValidTarget = typeof (evt === null || evt === void 0 ? void 0 : (_evt$target = evt.target) === null || _evt$target === void 0 ? void 0 : _evt$target.className) === "string";

    if (!isValidTarget || wrapperRef.current && !((_wrapperRef$current = wrapperRef.current) !== null && _wrapperRef$current !== void 0 && _wrapperRef$current.contains(target)) && target.id !== id) {
      onClickOutside();
    }
  }, [id, onClickOutside]);
  (0, _react.useEffect)(function () {
    document.addEventListener("click", handleClickOutside, false);
    return function () {
      return document.removeEventListener("click", handleClickOutside, false);
    };
  }, [handleClickOutside]);
  return [wrapperRef, id];
};

exports.useClickOutside = useClickOutside;